import React, { useState } from 'react';
import { Col, Container } from 'react-bootstrap';

const SecProjectRoadmap = () => {
    const [displayMintingMecanismDetails, setDisplayMintingMecanismDetails] = useState(false)
    return (
        <section className='projectroadmap-container'>
            <h2 className="section-title">
                Project Roadmap
                <div className="big-divider"></div>
            </h2>
            <Container>
                <Col>
                    <div className='phase-container'>
                        <div className='phase-text-container'>
                            <h3>July 2024</h3>
                            <h3 className='underlined'>100 Undercover Invaders Minting</h3>
                            <p>This is only the first step of a bigger journey !</p>
                            <p>
                                This collection will grant every holders a <span className='font-color3'>1:1 airdrop on our next collection </span>
                                and a <span className='font-color3'>percentage of all fees</span> collected by Invaders Labs.
                            </p>
                        </div>
                    </div>
                </Col>
                <div className="small-divider"></div>
                <Col>
                    <div className='phase-container'>
                        <div className='phase-text-container'>
                            <h3>July - October 2024</h3>
                            <h3 className='underlined'>Development of a revolutionary NFT Minting mecanism</h3>
                            <p>
                                While you will peacefully enjoy holding your NFT, we will work hard to develop a unique
                                and revolutionary NFT Minting mecanism:
                                <br></br>
                                <span
                                    className='showmore-text'
                                    onClick={() => setDisplayMintingMecanismDetails(!displayMintingMecanismDetails)}
                                >
                                    {displayMintingMecanismDetails ?
                                        "Show less" :
                                        "Show More"}
                                </span>
                                {displayMintingMecanismDetails ? (<ul>
                                    <li>
                                        NFT Traders & Holders <span className='font-color3'>earn money on each transaction</span>.
                                    </li>
                                    <li>
                                        After each trade made above floor price and with 15% profit, <span className='font-color3'>one free NFT will be airdropped</span> on sellers wallet.
                                        Which means a free 100% profit minimum !
                                    </li>
                                    <li>
                                        Undercover Invaders will be <span className='font-color3'>rewarded for being early</span> in this journey. You won't be deceived !
                                    </li>
                                </ul>) : ""}

                            </p>
                        </div>
                    </div>
                </Col>
                <div className="small-divider"></div>
                <Col>
                    <div className='phase-container'>
                        <div className='phase-text-container'>
                            <h3>October 2024 - To the infinity</h3>
                            <h3 className='underlined'>Minting of this brand new collection !</h3>
                            <p>
                                Everything will be kept secret until the minting. But you will not be deceived.
                                We will then continue grinding new NFT Collection and develop community.
                                <br></br>
                                <span className='font-color3'>Be part of the invasion !</span>
                            </p>
                        </div>
                    </div>
                </Col>
                <div className="small-divider"></div>
            </Container>

        </section >
    );
};

export default SecProjectRoadmap;