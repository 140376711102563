import React, { useEffect } from 'react';
import SecCollection from '../sections/SecCollection';
import Footer from '../components/Footer';

const Collection = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <SecCollection />
            <Footer />
        </div>
    );
};

export default Collection;